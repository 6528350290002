import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layouts/main-layout";
import Seo from "../components/seo";
import Container from "../components/container";
import Servicecard from "../components/servicecard";

// markup
const IndexPage = () => {
  const headerImage = (
    <StaticImage
      src="../images/heroimages/tidens_surfhuus_langeoog_station.jpg"
      alt="Windsurfen bei Tidens Surfhuus"
      width={2048}
      height={680}
      quality={90}
      className="w-full h-[35vw] min-h-[25vw] max-h-[50vh]"
      objectFit="cover"
    />
  );

  return (
    <Layout>
      <Seo
        title="Die Surfschule auf Langeoog"
        description="Tidens Surfhuus - die Surfschule und Kiteschule auf Langeoog. Unsere Surfschule bietet Kitesurfkurse, Windsurfkurse, Wingsurfkurse, Wellenreitkurse und SUP Kurse an."
      />
      <div className="bg-slate-500">{headerImage}</div>
      <Container>
        <div className="px-0 lg:px-[15%]">
          <h1 className="pb-2">
            Tidens Surfhuus – Die Surfschule und Kiteschule auf Langeoog
          </h1>
          <p>
            Moin!
            <br /> Von Mitte April bis Mitte Oktober findet ihr unsere
            VDWS-lizenzierte Surfschule und Kiteschule am Langeooger Weststrand.
            Wir bieten euch hochwertiges Surfmaterial aus dem aktuellen
            Modelljahr von Marken wie North, Severne, Tabou und vielen weiteren.
            Unsere VDWS-lizenzierten Wassersportlehrer schaffen eine familiäre
            Atmosphäre und sorgen dafür, dass sich jede(r) in unserer Surfschule
            willkommen fühlt.
          </p>
          <p>
            Bei uns könnt ihr an Surfkursen und Schnupperkursen im Wellenreiten,
            Wingsurfen, Wingfoilen, Windsurfen, Kitesurfen und Stand-Up Paddling
            (SUP) teilnehmen. Auch der Verleih von hochwertigem und stets
            aktuellem Material für alle Surf-Sportarten – sowohl für Anfänger
            als auch für Fortgeschrittene – gehört zu unserem Programm.
          </p>
          <p>
            <strong>Du bist im Winter auf Langeoog?</strong>
            <br />
            Vom 15. Oktober bis zum 15. April sind wir zwar nicht mit unserer
            Station am Strand vertreten, doch als Insulaner sind wir natürlich
            meistens auf der Insel und lieben es, auch im Winter aufs Wasser zu
            gehen!
            <br />
            Melde dich gerne, wenn du in dieser Zeit auf Langeoog bist und Lust
            hast, etwas dazuzulernen, oder einen Surfbuddy brauchst. Beachte
            jedoch, dass es abhängig von den Witterungsbedingungen erforderlich
            sein kann, dass du bereits fortgeschrittene Kenntnisse hast und über
            das passende Material (Winterneoprenanzug, Neoprenschuhe und
            -handschuhe) verfügst, um in der eiskalten Nordsee surfen zu gehen.
          </p>
        </div>

        <div className="my-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <Servicecard
            title="Wellenreiten"
            price="50"
            to="/kurse/wellenreiten"
            badge="Neu"
            image={
              <StaticImage
                src="../images/galery/wave/tidens_WAV_galery2.jpg"
                alt="Wellenreiten lernen Vorschau"
                placeholder="blurred"
                objectFit="fit"
                with={800}
                height={600}
                quality={80}
                className="rounded-t-md w-full aspect-4/3"
              />
            }
          />
          <Servicecard
            title="Wingsurfen / Wingfoilen"
            price="60"
            to="/kurse/wingfoilen"
            badge="Neu"
            image={
              <StaticImage
                src="../images/galery/wing/tidens_website_WIG-2.jpg"
                alt="Wingsurfen und Wingfoilen lernen Vorschau"
                placeholder="blurred"
                width={800}
                height={600}
                quality={80}
                className="rounded-t-md w-full aspect-4/3"
              />
            }
          />
          <Servicecard
            title="Windsurfen"
            price="50"
            to="/kurse/windsurfen"
            image={
              <StaticImage
                src="../images/galery/wind/tidens_WIS_galery-1.jpg"
                alt="Windsurfen lernen Vorschau"
                placeholder="blurred"
                width={800}
                height={600}
                quality={80}
                className="rounded-t-md w-full"
              />
            }
          />
          <Servicecard
            title="Kitesurfen"
            price="149"
            to="/kurse/kitesurfen"
            image={
              <StaticImage
                src="../images/galery/kite/tidensKTSgalery-5.jpg"
                alt="Kitesurfen lernen Vorschau"
                placeholder="blurred"
                width={800}
                height={600}
                quality={80}
                className="rounded-t-md w-full"
              />
            }
          />
          <Servicecard
            title="Stand Up Paddling - SUP"
            price="35"
            to="/kurse/sup"
            image={
              <StaticImage
                src="../images/galery/sup/tidens_sup_galery_1.jpg"
                alt="SUP lernen Vorschau"
                placeholder="blurred"
                objectFit="fit"
                width={800}
                height={600}
                quality={80}
                className="rounded-t-md w-full"
              />
            }
          />
          <Servicecard
            title="Gutscheine"
            price="35"
            badge="Surferlebnisse"
            to="https://tidens-shop.de/collections/geschenkgutscheine"
            external={true}
            image={
              <StaticImage
                src="../images/tidens-surfhuus-gutschein.jpg"
                alt="Tidens Surfhuus Gutscheine"
                placeholder="blurred"
                objectFit="fit"
                width={800}
                height={600}
                quality={80}
                className="rounded-t-md w-full"
              />
            }
          />
        </div>
      </Container>
    </Layout>
  );
};

export default IndexPage;
